/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                $(function() {
                    // $.scrollify({
                    //     section : ".j-slide",
                    //     interstitialSection:".j-slide--footer"
                    // });
                });
            },
            finalize: function () {
                // Toggle Menu
                var header = $('.o-header');
                header.on('click', '.o-header--toggle', function () {
                    header.toggleClass('s-menu-open');
                });
                header.find('.sub-menu').siblings('a').after('<span class="menu-slide"><i class="icon-arrow"></i></span>');
                header.find('.menu-slide').on('click',function(e){
                    $(this).toggleClass('open');
                    e.preventDefault();
                    e.stopPropagation();
                    $(this).siblings('.sub-menu').stop(true, true).slideToggle(200);
                });



                var testimonialCarousel = $('.j-carousel--testimonials');
                if(testimonialCarousel.length > 0){
                    testimonialCarousel.slick({
                        dots: true,
                        infinite: false,
                        arrows: true,
                    });
                }


                $('.j-faqs').on('click', '.j-faqs--toggle', function () {
                    var _this = $(this);
                    _this.parents('.j-faqs--single').toggleClass('o-faqs--open').siblings().removeClass('o-faqs--open');
                });


                $('.o-banner--carousel').slick({
                    arrows: false,
                    dots: false,
                    infinite: true,
                    fade: true,
                    autoplay: true,
                    swipe: false,
                    touchMove: false,
                    autoplaySpeed:  $('.o-banner--carousel').data('speed'),
                    
                });
            }
        },
        // Home page
        'page_template_homepage': {
            init: function () {
                // JavaScript to be fired on the home page

          

            },
            finalize: function () {
              
            }
        },

        // Home page
        'blog': {
            init: function () {
                // JavaScript to be fired on the home page

            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS


            }
        },


    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

